@each $direction in top, right, bottom, left
	&.has-tooltip-#{$direction}-mobile
		+mobile
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-tablet
		+tablet
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-tablet-only
		+tablet-only
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-touch
		+touch
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-desktop
		+desktop
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-desktop-only
		+desktop-only
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-until-widescreen
		+until-widescreen
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-widescreen
		+widescreen
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-widescreen-only
		+widescreen-only
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-until-fullhd
		+until-fullhd
			@include tooltip-direction(#{$direction})
	&.has-tooltip-#{$direction}-fullhd
		+fullhd
			@include tooltip-direction(#{$direction})

// Hidden breakpoints
&.has-tooltip-hidden-mobile
	+mobile
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-tablet
	+tablet
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-tablet-only
	+tablet-only
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-touch
	+touch
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-desktop
	+desktop
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-desktop-only
	+desktop-only
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-until-widescreen
	+until-widescreen
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-widescreen
	+widescreen
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-widescreen-only
	+widescreen-only
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-until-fullhd
	+until-fullhd
		&::after,
		&::before
			opacity: 0 !important
			display: none !important
&.has-tooltip-hidden-fullhd
	+fullhd
		&::after,
		&::before
			opacity: 0 !important
			display: none !important

// Text alignement breakpoints
@each $direction in (left, left), (centered, center), (right, right)
	$dir: nth($direction, 1)
	$text: nth($direction, 2)
	&.has-tooltip-text-#{$dir}-mobile
		+mobile
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-tablet
		+tablet
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-tablet-only
		+tablet-only
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-touch
		+touch
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-desktop
		+desktop
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-desktop-only
		+desktop-only
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-until-widescreen
		+until-widescreen
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-widescreen
		+widescreen
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-widescreen-only
		+widescreen-only
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-until-fullhd
		+until-fullhd
			&::before
				text-align: #{$text}
	&.has-tooltip-text-#{$dir}-fullhd
		+fullhd
			&::before
				text-align: #{$text}