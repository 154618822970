body {
    padding-top: 3.25rem;
}

.navbar-brand .navbar-item:first-child {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-brand .navbar-item:first-child img {
    max-height: 2.5rem ;
    height: 100%;
    width: auto;
}