@charset "utf-8";

@import "./styles/variables.scss";

@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/@creativebulma/bulma-tooltip/src/sass/index.sass";

@import "./styles/accessibility.scss";
@import "./styles/auth-state-visibility.scss";
@import "./styles/typography.scss";
@import "./styles/animations.scss";
@import "./styles/navbar.scss";
@import "./styles/footer.scss";
@import "./styles/backgrounds.scss";
@import "./styles/images.scss";
@import "./styles/card.scss";