@import 'variables'
@import 'position'
@import 'animation'

=tooltip-arrow
	&::after
		box-sizing: border-box
		color: $tooltip-color
		display: inline-block
		font-family: $tooltip-font-family
		font-size: $tooltip-font-size
		hyphens: auto
		opacity: 0
		overflow: hidden
		pointer-events: none
		position: absolute
		visibility: hidden
		z-index: $tooltip-z-index
		content: ''
		border-style: solid
		border-width: $tooltip-arrow-size
		border-color: rgba($tooltip-background-color, $tooltip-background-opacity) transparent transparent transparent
		margin-bottom: $tooltip-arrow-size * -1 + 1
	+tooltip-arrow-top

=tooltip-box
	&::before
		box-sizing: border-box
		color: $tooltip-color
		display: inline-block
		font-family: $tooltip-font-family
		font-size: $tooltip-font-size
		hyphens: auto
		opacity: 0
		overflow: hidden
		pointer-events: none
		position: absolute
		visibility: hidden
		z-index: $tooltip-z-index
		background: rgba($tooltip-background-color, $tooltip-background-opacity)
		border-radius: $tooltip-radius
		content: attr(data-tooltip)
		padding: $tooltip-padding
		text-overflow: ellipsis
		white-space: pre
	+tooltip-box-top

=tooltip-multiline
	&::before
		height: auto
		width: $tooltip-max-width
		max-width: $tooltip-max-width
		text-overflow: clip
		white-space: normal
		word-break: keep-all

[data-tooltip]
	&:not(.is-loading),
	&:not(.is-disabled),
	&:not([disabled])
		cursor: pointer
		overflow: visible
		position: relative

		+tooltip-box
		&.has-tooltip-arrow
			+tooltip-arrow

		&.has-tooltip-bottom
			+tooltip-direction('bottom')

		&.has-tooltip-left
			+tooltip-direction('left')

		&.has-tooltip-right
			+tooltip-direction('right')

		&.has-tooltip-multiline
			+tooltip-multiline

		&.has-tooltip-text-left
			&::before
				text-align: left
		&.has-tooltip-text-centered
			&::before
				text-align: center
		&.has-tooltip-text-right
			&::before
				text-align: right

		@each $name, $pair in $colors
			$color: nth($pair, 1)
			$color-invert: nth($pair, 2)
			&.has-tooltip-#{$name}
				&::after
					border-color: rgba($color, $tooltip-background-opacity) transparent transparent transparent !important
				&.has-tooltip-bottom
					&::after
						border-color: transparent transparent rgba($color, $tooltip-background-opacity) transparent !important
				&.has-tooltip-left
					&::after
						border-color: transparent transparent transparent rgba($color, $tooltip-background-opacity) !important
				&.has-tooltip-right
					&::after
						border-color: transparent rgba($color, $tooltip-background-opacity) transparent transparent !important
				&:before
					background-color: rgba($color, $tooltip-background-opacity)
					color: $color-invert

		+tooltip-hover
			opacity: 1
			visibility: visible

		&.has-tooltip-fade
			+tooltip-fade

		@import 'responsiveness'
	
span
	&[data-tooltip]
		border-bottom: 1px dashed $grey-lighter
		@each $name, $pair in $colors
			$color: nth($pair, 1)
			$color-invert: nth($pair, 2)
			&.has-tooltip-#{$name}
				border-bottom-color: lighten($color, 5%)

.control
	span
		&[data-tooltip]
			border-bottom: none
