.has-animation-blink {
    animation: 1s linear infinite keyframesBlink;
}

@keyframes keyframesBlink {
    0% {
            visibility: hidden;
    }

    30% {
            visibility: hidden;
    }

    100% {
            visibility: visible;
    }
}