.bakground-image-everyday {
    background-image: url('/src/assets/background-everyday.jpg');
    background-size: cover;
    background-position: center;
    background-clip: border-box;
    background-repeat: no-repeat;
}

.bakground-image-midyear {
    background-image: url('/src/assets/background-midyear.jpg');
    background-size: cover;
    background-position: center;
    background-clip: border-box;
    background-repeat: no-repeat;
}