body:not(.user-signed-in):not(.user-signed-out) .is-hidden-signed-in,
body:not(.user-signed-in):not(.user-signed-out) .is-hidden-signed-out {
        display: none !important;
}

body.user-signed-in .is-hidden-signed-in {
        display: none !important;
}

body.user-signed-out .is-hidden-signed-out {
        display: none !important;
}